import { OPERATOR_TYPE } from '@features/filters/types';
import i18n from '@features/i18n';
import { type ITargetGroup } from '@modules/automaticCampaigns/types';

export const automaticCampaignsTextLabel = {
  unsavedChanges: i18n.t('common.modal.unsaved.title'),
  uploadFileHint: i18n.t('campaign.common.target.label.file'),
  excludedPlayers: i18n.t('campaign.common.players.excluded'),
  uploadControl: i18n.t('campaign.common.target.control.file.size'),
  uploadControlTitle: i18n.t('campaign.common.target.control.file.upload'),
  uploadFileTooltip: i18n.t('campaign.common.target.control.file.tooltip'),
  ratesTooltip: i18n.t('campaign.automatic.details.accumulated.button.rate'),
  accumulatedAmountTitle: i18n.t(
    'campaign.automatic.details.accumulated.title'
  ),
  activatingStatus: i18n.t(
    'campaign.automatic.details.modal.status.active.title'
  ),
  changeBrandPopup: i18n.t(
    'campaign.common.general.modal.brand.change.automatic'
  ),
  campaignRelationTooltip: i18n.t(
    'campaign.common.target.control.relation.tooltip'
  ),
  enableDailyTimeRangesTooltip: i18n.t(
    'campaign.common.time.control.ranges.tooltip'
  ),
  deactivatingStatus: i18n.t(
    'campaign.automatic.details.modal.status.inactive.title'
  ),
  overrideBonusTimezoneTooltip: i18n.t(
    'campaign.common.general.control.apply.tooltip'
  ),
  applicablePlayers: i18n.t(
    'campaign.automatic.grid.modal.info.segment.applicable.label'
  ),
  ratesDisabledTooltip: i18n.t(
    'campaign.automatic.details.accumulated.button.tooltip.disable'
  ),
  campaignRelationPopupInactiveItemTooltip: i18n.t(
    'campaign.automatic.grid.modal.relation.tooltip'
  ),
  excludeDuplicatedBetsTooltip: i18n.t(
    'campaign.automatic.details.accumulated.control.exclude.tooltip'
  ),
  accumulatedAmountSwitchTooltip: i18n.t(
    'campaign.automatic.details.accumulated.control.enable.tooltip'
  ),
  uploadTypeTooltipOptions: [
    i18n.t('campaign.common.target.control.append.tooltip'),
    i18n.t('campaign.common.target.control.replace.tooltip'),
  ],
};

export const initialFilters = {
  id: { value: '', inversion: false, operator: OPERATOR_TYPE.EQUAL },
  brands: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  trigger: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  enabled: { value: false, inversion: true, operator: OPERATOR_TYPE.EQUAL },
  betActionType: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  campaignName: { value: '', inversion: false, operator: OPERATOR_TYPE.LIKE },
  targetGroup: { value: null, inversion: false, operator: OPERATOR_TYPE.EQUAL },
  bonuses: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
};

export const targetGroup: ITargetGroup = {
  SEGMENT: i18n.t('campaign.automatic.grid.modal.target.segment'),
  OPEN_FOR_ALL: i18n.t('campaign.automatic.grid.modal.target.all'),
};
